import Konva from "konva";

export function getColorFromClassName(className: string): string {
    switch (className) {
      case 'red':
        return '#C90A0A';
      case 'orange':
        return '#F3A101';
      case 'yellow':
        return '#EEE500';
      case 'green':
        return '#26CA0B';
      case 'blue':
        return '#054DF2';
      case 'purple':
        return '#720ADA';
      case 'black':
        return '#000000';
      case 'white':
        return '#FFFFFF';
      case 'brown':
        return '#A52A2A';
      case 'gray':
        return '#808080';
      case 'pink':
        return '#EB4B84';
      case 'lilla':
        return '#D666DF';
      case 'cyan':
        return '#2FDCDC';
      default:
        return ''; // Handle default case
    }
}

export function implementZoom(scale : number, stage : Konva.Stage) {
  stage.on('wheel', (e) => {
    e.evt.preventDefault();

    // zoom explanation, refer to: https://stackoverflow.com/questions/2916081/zoom-in-on-a-point-using-scale-and-translate
    const oldScale = scale;
    const pointer = stage.getPointerPosition();


    // mousePointTo is the point relative to the stage's current position and scale
    const mousePointTo = {
        x: (pointer!.x - stage.x()) / oldScale,
        y: (pointer!.y - stage!.y()) / oldScale
    };

    // Calculate the new scale based on the wheel direction
    const newScale = e.evt.deltaY > 0 ? scale * 1.1 : scale / 1.1;
    scale = newScale;

    // Calculate the new position to keep the mouse point in the same position after scaling
    const newPos = {
        x: pointer!.x - mousePointTo.x * scale,
        y: pointer!.y - mousePointTo.y * scale
    };

    stage.position(newPos);
    stage.scale({ x: scale, y: scale });
  });
}

export function simulateTextEditing(tr : Konva.Transformer, textNode : any, body : any, clientRect? : any, group? : Konva.Group) {
  textNode.on('dblclick dbltap', (e : any) => {
    // hide text node and transformer:
    textNode.hide();
    tr.hide();
    // this.toolBar.nativeElement.style.left = `-20%`;

    // this.selectionBar?.openText(e.target);
    const textarea = createTextArea(textNode,e.target.getStage()!, body, clientRect ?? undefined, group ?? undefined);

    if(!textarea) {
      return;
    }

    const removeTextarea = () => {
      textarea!.parentNode!.removeChild(textarea!);
      window.removeEventListener('click', handleOutsideClick);
      textNode.show();
      tr.show();
      tr.forceUpdate();
    }

    const setTextareaWidth = (newWidth : number) => {
      if (!newWidth) {
        // set width for placeholder
        newWidth = textNode.placeholder.length * textNode.fontSize();
      }
      // some extra fixes on different browsers
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      const isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isSafari || isFirefox) {
        newWidth = Math.ceil(newWidth);
      }

      textarea!.style.width = newWidth + 'px';
    }

    textarea.addEventListener('keydown', function (e) {
      // hide on enter
      // but don't hide on shift + enter
      if (e.key === "Enter" && !e.shiftKey) {
        textNode.text(this.value);
        if (group) {
          if (textNode.height() * group!.scaleY() > group!.children![0].height() * group!.scaleY()) {
            group!.children![0].height(textNode.height() * group!.scaleY());
          }
        }
        removeTextarea();
      }
      // on esc do not set value back to node
      if (e.key === "Escape") {
        removeTextarea();
      }
    });

    textarea.addEventListener('keydown', function (e) {
      const scale = textNode.getAbsoluteScale().x;
      setTextareaWidth(textNode.width() * scale);
      this.style.height = 'auto';
      this.style.height =
        this.scrollHeight + textNode.fontSize() + 'px';
    });

    const handleOutsideClick = (e : any) => {
      if (e.target !== textarea) {
        textNode.text(textarea!.value);
        if (group) {
          if (textNode.height() * group!.scaleY() > group!.children![0].height() * group!.scaleY()) {
            group!.children![0].height(textNode.height() * group!.scaleY());
          }
        }
        removeTextarea();
      }
    }
    setTimeout(() => {
      window.addEventListener('click', handleOutsideClick);
    });
  });
}

function createTextArea(textNode : any, stage : Konva.Stage, body : any, clientRect : any, group? : Konva.Group) : HTMLTextAreaElement {
  // create textarea over canvas with absolute position

  // at first lets find position of text node relative to the stage:
  const textPosition = textNode.absolutePosition();

  // so position of textarea will be the sum of positions above:
  const areaPosition = {
    x: (clientRect ? clientRect.x : body.nativeElement.getBoundingClientRect().x) + textPosition.x,
    y: (clientRect ? clientRect.y : body.nativeElement.getBoundingClientRect().y) + textPosition.y
  };

  // create textarea and style it
  const textarea = document.createElement('textarea');
  body.nativeElement.appendChild(textarea);

  textarea.value = textNode.text();
  textarea.style.position = 'absolute';
  textarea.style.top = areaPosition.y + 'px';
  textarea.style.left = areaPosition.x + 'px';
  textarea.style.width = textNode.width() * stage!.scaleX() - textNode.padding() * 2 + 'px';
  textarea.style.height =
    textNode.height() - textNode.padding() * 2 + 5 + 'px';
  textarea.style.fontSize = textNode.fontSize() * stage!.scaleX() + 'px';
  textarea.style.border = 'none';
  textarea.style.padding = '0px';
  textarea.style.margin = '0px';
  textarea.style.overflow = 'hidden';
  textarea.style.background = 'none';
  textarea.style.outline = 'none';
  textarea.style.resize = 'none';
  textarea.style.lineHeight = textNode.lineHeight();
  textarea.style.fontFamily = textNode.fontFamily();
  textarea.style.transformOrigin = 'left top';
  textarea.style.textAlign = textNode.align();
  textarea.style.color = textNode.fill();
  const rotation = group ? group.rotation() : textNode.rotation();
  let transform = '';
  if (rotation) {
    transform += 'rotateZ(' + rotation + 'deg)';
  }

  let px = 0;

  const isFirefox =
    navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  if (isFirefox) {
    px += 2 + Math.round(textNode.fontSize() / 20);
  }
  transform += 'translateY(-' + px + 'px)';

  textarea.style.transform = transform;

  // reset height
  textarea.style.height = 'auto';
  // after browsers resized it we can set actual value
  textarea.style.height = textarea.scrollHeight + 3 + 'px';

  textarea.focus();
  return textarea;
}

export const defaultFonts = [
  {font: "Acme", testText:"Acme"},
  {font: "Anta", testText:"Anta"},
  {font: "Anton", testText:"Anton"},
  {font: "Arbutus", testText:"Arbutus"},
  {font: "Architects Daughter", testText:"Architects Daughter"}, 
  {font: "Arial", testText:"Arial Writing"}, 
  {font: "Barriecito", testText:"Barriecito"}, 
  {font: "Bebas Neue", testText:"Bebas Neue"},
  {font: "Black Ops One", testText:"Black Ops One"},
  {font: "Bodoni Moda", testText:"Bodoni Moda"},
  {font: "Bree Serif", testText:"Bree Serif"},
  {font: "Bungee", testText:"Bungee"},
  {font: "Cantora One", testText:"Cantora One"},
  {font: "Caveat", testText:"Caveat"},
  {font: "Carter One", testText:"Carter One"},
  {font: "Changa One", testText:"Changa One"},
  {font: "Chango", testText:"Chango"},
  {font: "Comic Neue", testText:"Comic Neue"},
  {font: "Courier Prime", testText:"Courier Prime"},
  {font: "DM Serif Display", testText:"DM Serif Display"},
  {font: "East Sea Dokdo", testText:"East Sea Dokdo"},
  {font: "Exo 2", testText:"Exo 2"},
  {font: "Faustina", testText:"Faustina"},
  {font: "Freckle Face", testText:"Freckle Face"},
  {font: "Fugaz One", testText:"Fugaz One"},
  {font: "Gloria Hallelujah", testText:"Gloria Hallelujah"},
  {font: "Grape Nuts", testText:"Grape Nuts"},
  {font: "Honk", testText:"Honk"},
  {font: "Indie Flower", testText:"Indie Flower"},
  {font: "Jacquarda Bastarda", testText:"Jacquarda"},
  {font: "Kalam", testText:"Kalam"},
  {font: "Kanit", testText:"Kanit"},
  {font: "Kdam Thmor Pro", testText:"Kdam Thmor Pro"},
  {font: "Kode Mono", testText:"Kode Mono"},
  {font: "Libre Baskerville", testText:"Libre Baskerville"},
  {font: "Lilita One", testText:"Lilita One"},
  {font: "Lobster", testText:"Lobster Writing"},
  {font: "Lora", testText:"Lora"},
  {font: "Luckiest Guy", testText:"Luckiest Guy"},
  {font: "Macondo", testText:"Macondo"},
  {font: "MedievalSharp", testText:"MedievalSharp"},
  {font: "Micro 5", testText:"Micro 5"},
  {font: "Miniver", testText:"Miniver"},
  {font: "Merriweather", testText:"Merriweather"},
  {font: "Monofett", testText:"Monofett"},
  {font: "Noto Sans Mono", testText:"Noto Sans Mono"},
  {font: "Orbitron", testText:"Orbitron"},
  {font: "Outfit", testText:"Outfit"},
  {font: "Pacifico", testText:"Pacifico"},
  {font: "Peralta", testText:"Peralta"},
  {font: "Permanent Marker", testText:"Permanent Marker"},
  {font: "Poor Story", testText:"Poor Story"},
  {font: "Poppins", testText:"Poppins"},
  {font: "Prompt", testText:"Prompt"},
  {font: "Protest Guerrilla", testText:"Protest Guerrilla"},
  {font: "Protest Riot", testText:"Protest Riot"},
  {font: "PT Mono", testText:"PT Mono"},
  {font: "Ribeye", testText:"Ribeye"},
  {font: "Righteous", testText:"Righteous"},
  {font: "Roboto Slab", testText:"Roboto Slab"},
  {font: "Rubik", testText:"Rubik"},
  {font: "Sarina", testText:"Sarina"},
  {font: "Sedgwick Ave Display", testText:"Sedgwick Ave Display"},
  {font: "Shantell Sans", testText:"Shantell Sans"},
  {font: "Silkscreen", testText:"Silkscreen"},
  {font: "Sixtyfour", testText:"Sixtyfour"},
  {font: "Special Elite", testText:"Special Elite"},
  {font: "Square Peg", testText:"Square Peg"},
  {font: "Stick", testText:"Stick"},
  {font: "Titillium Web", testText:"Titillium Web"},
  {font: "Trade Winds", testText:"Trade Winds"},
  {font: "Yellowtail", testText:"Yellowtail"},
  {font: "Yeseva One", testText:"Yeseva One"},
  {font: "Zeyada", testText:"Zeyada"},
]


// implementCopyPaste() {
  //   let copiedElement : any;
  //   const self = this;
  //   document.addEventListener('keydown', function (e) {
  //     if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
  //       console.log(self.selectedNode)
  //       // clone is not a function??  https://konvajs.org/api/Konva.Image.html#clone__anchor
  //       copiedElement = self.selectedNode.clone();
  //       console.log("copied element: ", copiedElement)
  //     } else if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
  //       if (copiedElement) {
  //         copiedElement.x(copiedElement.x() + 10);
  //         copiedElement.y(copiedElement.y() + 10);
    
  //         self.layer!.add(copiedElement);
  //         self.stage!.draw();
  //       }
  //     }
  //   });
  // }

export function getUserSchoolYearName(value: string): string {
    switch (value) {
        case "first":
            return "Primo Anno";
        case "second":
            return "Secondo Anno";
        case "third":
            return "Terzo Anno";
        case "fourth":
            return "Quarto Anno";
        case "fifth":
            return "Quinto Anno";
        default:
            return '';
    }
}