import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, RouterModule, Routes, ActivatedRoute, Router } from '@angular/router';
import { LoggedInGuard, NotLoggedInGuard, UserTypeGuard } from './services/authentication.service';
import { ExternalUrlRedirectResolver } from './services/external-url-redirect-resolver.service';



const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
        canActivate: [NotLoggedInGuard]
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule),
        canActivate: [NotLoggedInGuard]
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'home-public',
        loadChildren: () => import('./pages/home-public/home-public.module').then(m => m.HomePublicPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    },
    {
        path: 'wall',
        loadChildren: () => import('./pages/wall/wall.module').then(m => m.WallPageModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'diary',
        loadChildren: () => import('./pages/diary/diary.module').then(m => m.DiaryModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'rooms',
        loadChildren: () => import('./pages/rooms/rooms.module').then(m => m.RoomsModule),
        canActivate: [LoggedInGuard, UserTypeGuard],
    },
    {
        path: 'lessons',
        loadChildren: () => import('./pages/lessons/lessons.module').then(m => m.LessonsModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'lessons',
        loadChildren: () => import('./pages/lessons/lessons.module').then(m => m.LessonsModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'lesson-subject',
        loadChildren: () => import('./pages/lesson-subject/lesson-subject.module').then(m => m.LessonSubjectModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'lesson-playlist',
        loadChildren: () => import('./pages/lesson-playlist/lesson-playlist.module').then(m => m.LessonPlaylistModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'lesson-show',
        loadChildren: () => import('./pages/lesson-show/lesson-show.module').then(m => m.LessonSubjectModule),
        canActivate: [LoggedInGuard]
    },
    {
        path: 'lesson-subject',
        loadChildren: () => import('./pages/lesson-subject/lesson-subject.module').then(m => m.LessonSubjectModule)
    },
    {
        path: 'lesson-playlist',
        loadChildren: () => import('./pages/lesson-playlist/lesson-playlist.module').then(m => m.LessonPlaylistModule)
    },
    {
        path: 'lesson-show',
        loadChildren: () => import('./pages/lesson-show/lesson-show.module').then(m => m.LessonSubjectModule)
    },
    {
        path: 'invite-room',
        loadChildren: () => import('./pages/invite-room/inviteRoom').then(m => m.InviteRoomModule),
        resolve: {
            url: ExternalUrlRedirectResolver
        },
        data: {
            externalUrl: () => window.location.href.replace('/invite-room', '/rooms')
        },
        canActivate: [LoggedInGuard]
    },
    {
        path: 'request-password',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        data: { queryParams: { resetpassword_key: null } }
    },
    {
        path: 'complete-signup',
        loadChildren: () => import('./pages/complete-signup/complete-signup.module').then(m => m.CompleteSignupModule),
    },
    {
        path: 'success-payment',
        loadChildren: () => import('./pages/payment-success/paymentSuccess.module').then(m => m.PaymentSuccessModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                window.location.href = route.data['externalUrl'];
            }
        }
    ]
})
export class AppRoutingModule {
    constructor(private router: Router) {
        this.router.errorHandler = (error: any) => {
            this.router.navigate(['/']);
        }
    }
}
